import React from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';
import Accessibility from '../components/Accessibility/Accessibility';

const AccessibilityPage = () => {
  return (
    <>
      <SEO pageName="accessibility" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <main id="main" tabIndex="-1">
          <Hero
            img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
            title="accessibility statement"
          />
          <Accessibility />
        </main>
        <Footer />
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default AccessibilityPage;
