import React from 'react';
import useScript from '../../hooks/useScript';
import { Root, Container, StampImg, Title, Body } from './Accessibility.style';

import StampSrc from '../../images/stamp-beige.svg';

const Accessibility = () => {
  useScript('https://app.termly.io/embed-policy.min.js');

  return (
    <Root>
      <Container>
        <StampImg src={StampSrc} alt="" />
        <Body>
          <Title>Accessibility Statement</Title>
          <p>
            <b>Accessibility Assistance:</b> If you have difficulty using or
            accessing any element of this website or on any James Ownby Reserve mobile app
            or tool, please feel free to call us at <a href='tel:865-436-6995'>(865) 436-6995</a> or email us
            at  <a href="mailto:customerservice@osdistillery.com">customerservice@osdistillery.com</a> and we will work with you to
            provide the information, item, or transaction you seek through a
            communication method that is accessible for you consistent with
            applicable law (for example, through telephone support).
          </p>
          <p>
            <b>Goal of being better for All:</b> James Ownby Reserve desires to provide a
            positive customer experience to all our customers, and we aim to
            promote accessibility and inclusion. Our goal is to permit our
            customers to successfully gather information and transact business
            through our website. Whether you are using assistive technologies
            like a screen reader, a magnifier, voice recognition software, or
            captions for videos, our goal is to make your use of James Ownby Reserve's
            website a successful and enjoyable experience.
          </p>
          <p>
            <b>Actions:</b> We are taking a variety of steps and devoting
            resources to further enhance the accessibility of our website.
          </p>
          <p>
            <b>Ongoing Effort:</b> Although we are proud of the efforts that we
            have completed and that are in-progress, we at James Ownby Reserve view
            accessibility as an ongoing effort.
          </p>
          <p>
            <b>Feedback:</b> Please contact us if you have any feedback or
            suggestions as to how we could improve the accessibility of this
            website.
          </p>
        </Body>
      </Container>
    </Root>
  );
};

export default Accessibility;
